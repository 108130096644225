.editor {
	@apply border rounded transition duration-150 ease-in-out flex text-left relative;
}

.editor > .editor-input {
	@apply bg-transparent w-full;
}

.editor > .placeholder {
	@apply text-muted text-sm absolute w-full top-0 left-0 p-2;
}

.editor.default .editor-input {
	@apply px-3 py-2 text-sm;
}

.editor.big .editor-input {
	@apply !px-6 !py-2.5 text-base;
}

.editor.disabled {
	@apply !pointer-events-none bg-gray-300 !text-gray-600 opacity-70 dark:bg-black;
}

.editor.main {
	@apply bg-gray-50 hover:border-gray-400 focus-within:border-gray-400 dark:bg-black dark:text-light;
}

.editor-input p:last-child {
	@apply !m-0;
}

.editor [contentEditable='true']:empty:not(:focus):before {
	content: attr(placeholder);
	@apply block text-muted;
}

.editor [contentEditable='true']:empty:focus:before {
	content: ' ';
}
